$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

$fluid-type-screen-min: 22.5rem;
$fluid-type-screen-max: 90rem;

$pyrat-black: #292929;
$pyrat-black-2: #050505;
$pyrat-white: #fff;
$pyrat-grey: #F9F9F9;
$pyrat-grey-0: #E9E9E9;
$pyrat-grey-1: #D9D9D9;
$pyrat-grey-2: #BCBCBC;
$pyrat-grey-3: #9D9D9D;
$pyrat-grey-4: #8C8C8C;
$pyrat-grey-5: #5B5B5B;
$pyrat-yellow: #F6C469;
$pyrat-green: #80CE76;
$pyrat-blue-light: #DBF3FD;
$pyrat-blue: #7673FF;
$pyrat-purple: #9F81F4;

$selection: $pyrat-black;
$linkcolor: $pyrat-black;

:root {
    --gradient: 45deg, #ff0 0%, #ff0 5%, #000 5%, #000 10%;
}
