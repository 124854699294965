.btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all 0.2s ease;
    &.primary {
        width: 124px;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        border: 1px solid transparent;
        background-color: $pyrat-white;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-Formula();
        font-weight: 500;
        font-size: 14px;
        color: $pyrat-black;
        margin: 12px 0 0 auto;
        &.stop {
            color: $pyrat-white;
            border-color: $pyrat-white;
            background-color: transparent;
        }
        &[disabled] {
            opacity: .3;
        }
    }
    &.secondary {
        border-radius: 12px;
        border: 1px solid $pyrat-grey-3;
        padding: 28px 20px;
        .text {
            @include font-PlexMono();
            font-size: 12px;
            line-height: 1;
            color: $pyrat-grey-5;
            letter-spacing: 0.05em;
            &:not(:last-child) {
                margin: 0 0 10px;
            }
        }
    }
    &.icon-info {
        width: 30px;
        height: 30px;
        margin: 0 0 0 auto;
        border-radius: 100%;
        border: 1px solid $pyrat-grey-3;
        background-color: $pyrat-white;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-PlexMono();
        font-size: 17px;
        line-height: 1;
        color: $pyrat-grey-5;
    }
    &.popin-close-btn {
        width: 55px;
        height: 55px;
        border-radius: 100%;
        border: 1px solid $pyrat-grey-0;
        background-color: $pyrat-white;
        position: absolute;
        top: 75px;
        right: 105px;
        z-index: 1;
        &::before,
        &::after {
            content: '';
            width: 20px;
            height: 2px;
            display: block;
            background-color: $pyrat-black;
            position: absolute;
            top: 50%;
            left: 50%;
            will-change: top, bottom, transform;
        }
        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
    &.help {
        @include font-Formula();
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        border-radius: 6px;
        padding: 28px 36px 24px 68px;
        background-color: $pyrat-yellow;
        position: relative;
        &::before,
        &::after {
            content: '';
            width: 18px;
            height: 1px;
            position: absolute;
            top: 50%;
            left: 32px;
            background-color: $pyrat-black;
        }
        &::before {
            transform: translateY(-50%) rotate(45deg);
        }
        &::after {
            transform: translateY(-50%) rotate(-45deg);
        }
    }
    &.ui {
        min-width: 130px;
        height: 42px;
        border-radius: 9px;
        border: 1px solid $pyrat-black;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
        &.load-scenario {
            &[disabled] {
                color: inherit;
                cursor: not-allowed;
                opacity: 0.5;
                transition: opacity 0.2s ease;
            }
        }
    }
    &.pill {
        @include font-Formula();
        font-weight: 500;
        font-size: 13px;
        line-height: 1;
        color: $pyrat-white;
        padding: 13px 42px 12px;
        border-radius: 40px;
        border: 1px solid $pyrat-white;
    }
}

.btn-container {
    display: flex;
    flex-direction: column;
    .btn {
        &:not(:last-child) {
            margin: 0 0 20px;
        }
    }
}
