* {
    &:focus {
        outline: 4px solid $pyrat-blue;
        outline-offset: 2px;
    }
}

body {
    @include font-Formula();
    font-weight: 300;
    &.oh {
        height: 100vh;
        position: relative;
        touch-action: none;
        -webkit-overflow-scrolling: touch;
        overflow: hidden !important;
        overscroll-behavior: none;
    }
}

main {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.wip {
    border-width: 10px;
    border-style: solid;
    border-image: repeating-linear-gradient(var(--gradient)) 10;
}
