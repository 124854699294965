.screen.scenario {
    background-color: $pyrat-grey-1;
    display: none;
    align-items: center;
    justify-content: center;
    .main-container {
        width: 100vw;
        height: 100vh;
        max-width: 1920px;
        max-height: 1080px;
        display: grid;
        grid-template-rows: 43.5% 1fr 42%;
        padding: 6.25rem 4.375rem 3.75rem 5rem;
        position: relative;
    }
    .pane {
        height: 100%;
        &.top {
            grid-row: 1 / 1;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            max-height: 400px;
            .cable-container {
                flex: 1;
                height: 100%;
                padding: 10px 0 0;
            }
        }
        &.bottom {
            grid-row: 3 / 4;
            max-height: 380px;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            gap: 0 30px;
            border-radius: 12px;
            padding: 10px 22px 10px 10px;
            background-color: $pyrat-black;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
            .pane-bottom-box {
                display: flex;
                align-items: stretch;
                gap: 0 10px;
            }
        }
    }
    .cables-container {
        grid-row: 2 / 3;
        position: relative;
        .cable-container {
            position: absolute;
            top: 0;
            bottom: -10px;
            svg {
                height: 100%;
            }
            &#cable3 {
                right: 72%;
            }
            &#cable4 {
                right: 43%;
            }
            &#cable5 {
                right: 21%;
            }
        }
    }
    .tile {
        border-radius: 6px;
        border: 1px solid $pyrat-grey-3;
        position: relative;
        overflow: hidden;
        .tile-top,
        .tile-bottom {
            display: flex;
            align-items: center;
            position: absolute;
            right: 15px;
            left: 15px;
            z-index: 1;
        }
        .tile-top {
            top: 14px;
        }
        .tile-bottom {
            gap: 0 40px;
            bottom: 6px;
            justify-content: space-between;
        }
        .led {
            margin: 0 16px 0 0;
            &.activity {
                animation: led 0.2s infinite;
            }
        }
        .tile-tag {
            @include font-PlexMono();
            font-size: 17px;
            line-height: 22px;
            color: $pyrat-grey-5;
            padding: 10px;
            border-radius: 6px;
            background-color: $pyrat-grey-0;
            letter-spacing: 0.05rem;
            text-align: center;
            position: absolute;
            bottom: 18px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            &.scenario-1 {
                width: 70%;
            }
        }
        .tile-content {
            width: 100%;
            height: 100%;
            padding: 64px 10px 10px;
            display: flex;
            flex-direction: column;
            gap: 0.875rem 0;
            align-items: center;
            .tile-bar {
                width: 100%;
                display: flex;
                gap: 1.0625rem 0;
                flex-direction: column;
                align-items: center;
                border-radius: 6px;
                border: 1px solid $pyrat-grey-2;
                padding: 12px;
            }
            .tile-bar-title {
                line-height: 1;
            }
            .img-container,
            .lottie-container {
                height: 72px;
                svg,
                div {
                    height: 100%;
                }
                svg {
                    max-width: none;
                }
            }
            .result-title {
                width: 100%;
                height: 2.25rem;
                @include font-PlexMono();
                font-weight: 500;
                font-size: 17px;
                position: relative;
                svg {
                    height: 10px;
                }
                span {
                    border-radius: 5px;
                    padding: 5px 10px;
                    white-space: nowrap;
                    @include absolute-center();
                    transition: opacity 0.2s ease;
                    &.text-on {
                        color: $pyrat-black;
                        border: 1px solid $pyrat-grey-0;
                        background-color: $pyrat-white;
                        opacity: 0;
                        path {
                            fill: $pyrat-black;
                        }
                    }
                    &.text-off {
                        color: $pyrat-white;
                        border: 1px solid transparent;
                        background-color: $pyrat-black;
                        opacity: 1;
                    }
                }
            }
        }
        .input-range-value {
            width: 140px;
            @include font-Formula();
            font-weight: 300;
            font-size: 42px;
            line-height: 1;
            letter-spacing: 0.05rem;
            color: var(--theme);
            text-align: right;
            span {
                font-size: 16px;
            }
        }
        .tile-title {
            @include font-Formula();
            font-weight: 500;
            font-size: 16px;
            line-height: 1;
            letter-spacing: 0.05rem;
            color: var(--theme);
        }
        .lottie-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            svg {
                width: 100%;
                max-width: 300px;
            }
            &.absolute {
                position: relative;
                background: url('../img/grid.svg') center / 100% 100% no-repeat;
                div {
                    position: absolute;
                    inset: 0;
                }
                svg {
                    max-width: none;
                }
            }
        }
        .svg-placeholder,
        .svg-result {
            height: 100%;
            display: block;
        }
        #scenario1Decision2,
        #scenario1Decision2 {
            width: 140%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        #scenario1Decision2,
        #scenario2Decision2,
        #scenario2DecisionB,
        .svg-result {
            display: none;
        }
        &.situation,
        &.decision {
            flex: 0 1 28.4%;
            aspect-ratio: 5 / 4;
            color: $pyrat-black;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        }
        &.situation {
            background-color: $pyrat-white;
            #scenario1Situation1,
            #scenario1Situation2 {
                &.hide {
                    display: none;
                }
            }
            &.scenario-2 {
                .lottie-container {
                    padding-top: 60px;
                    justify-content: normal;
                    svg {
                        max-width: 388px;
                    }
                }
            }
        }
        &.network {
            flex: 0 1 22.7%;
            aspect-ratio: 1 / 1;
            color: $pyrat-white;
            background-color: $pyrat-black;
        }
        &.decision {
            background-color: $pyrat-grey;
            .tile-bar {
                &.grey {
                    background-color: $pyrat-grey-1;
                }
                &.black {
                    background-color: $pyrat-white;
                    border-color: $pyrat-black;
                }
            }
            .lottie-container {
                min-height: auto;
            }
            &.on {
                .svg-placeholder,
                .svg-result {
                    display: none;
                }
                #scenario1Decision2,
                #scenario2Decision2 {
                    display: block;
                }
                // .result-title {
                //     .text-on {
                //         opacity: 1;
                //     }
                //     .text-off {
                //         opacity: 0;
                //     }
                // }
            }
            &.result {
                .svg-placeholder,
                #scenario1Decision2,
                #scenario2Decision2 {
                    display: none;
                }
                .svg-result {
                    display: block;
                }
            }
        }
        &.marge,
        &.precision {
            flex: 0 1 49.6%;
            aspect-ratio: 25/18;
        }
        &.marge {
            --theme: #{$pyrat-yellow};
            grid-column: 1 / 2;
        }
        &.precision {
            --theme: #{$pyrat-purple};
            grid-column: 3 / 4;
        }
        &.analyse {
            grid-column: 5 / 6;
            height: 100%;
            aspect-ratio: 1 / 1;
            color: $pyrat-white;
            background-color: $pyrat-black;
            &.background {
                img {
                    width: 100%;
                    max-width: 330px;
                    @include absolute-center();
                }
            }
            .tile-top {
                top: 5px;
                left: 30px;
            }
            .lottie-container {
                max-width: 330px;
                max-height: 330px;
                @include absolute-center();
                svg {
                    max-width: none;
                }
                &.grid {
                    background-color: #000;
                }
                &.anim {
                    // max-width: 290px;
                    // max-height: 290px;
                    &::before {
                        content: '';
                        height: 3.375rem;
                        position: absolute;
                        top: -14px;
                        right: -14px;
                        left: -14px;
                        z-index: 1;
                        background: linear-gradient(to bottom, $pyrat-black 0%, rgba($pyrat-black, 0) 100%);
                    }
                }
                &.axes {
                    max-width: 360px;
                    max-height: 360px;
                }
            }
            .coord {
                color: $pyrat-white;
                @include font-Formula();
                font-weight: 500;
                font-size: 8px;
                position: absolute;
                z-index: 2;
                &.n {
                    top: 2px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                &.e {
                    top: 50%;
                    left: 100%;
                    transform: translate(-80%, -50%) rotate(90deg);
                }
                &.s {
                    top: 100%;
                    left: 50%;
                    transform: translate(-50%, -100%);
                }
                &.w {
                    top: 50%;
                    left: 2px;
                    transform: translate(-40%, -50%) rotate(90deg);
                }
                &.c {
                    top: calc(50% + 4px);
                    left: calc(50% + 4px);
                }
            }
        }
    }
    .tile-board {
        grid-column: 7 / 8;
        flex: 0 1 17%;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        .tile-board-title {
            margin: 0 0 auto;
            img {
                height: 17px;
            }
        }
        .tile-board-content {
            flex: 1;
            display: flex;
            gap: 12px 0;
            flex-direction: column;
            justify-content: flex-end;
            margin: 0 0 15px;
            .legends {
                display: flex;
                flex-direction: column;
                gap: 1.125rem 0;
                margin: 0 0 auto;
                .legend {
                    display: flex;
                    gap: 0 8px;
                    align-items: center;
                    .icon {
                        flex: 0 0 1rem;
                        aspect-ratio: 1 / 1;
                        &.blue {
                            background-color: $pyrat-blue-light;
                        }
                        &.grey {
                            background-color: #828E93;
                        }
                        &.black {
                            border: 1px solid #828E93;
                            background-color: #2B2B2B;
                        }
                    }
                    .text {
                        font-size: 14px;
                        color: $pyrat-white;
                    }
                }
            }
        }
        .tile-board-content-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .tile-board-content-bottom {
            display: flex;
        }
        .title {
            @include font-PlexMono();
            font-size: 17px;
            line-height: 1;
            color: $pyrat-white;
            letter-spacing: 0.05rem;
            text-align: center;
        }
        .loading-bar {
            width: 100%;
            height: 10px;
            border: 1px solid $pyrat-white;
            border-radius: 38px;
            position: relative;
            transition: margin-bottom 0.2s ease;
            .percent {
                width: 0%;
                height: 8px;
                border-radius: 4px;
                background-color: $pyrat-white;
                position: absolute;
                top: 0;
                left: 0;
                transition: width 0.2s linear;
            }
        }
    }
    &.loading-please-wait {
        .led {
            @extend .activity;
        }
        .tile-board {
            .btn {
                @extend .stop;
            }
        }
    }
    &.show {
        display: flex;
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes led {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
