.screen.intro {
    position: relative;
    background-color: $pyrat-black;
    .loading-text {
        font-family: 'Courier New', Courier, monospace;
        font-weight: bold;
        font-size: 14px;
        color: $pyrat-white;
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        inset: 0;
        opacity: 0;
        pointer-events: none;
        animation: blink 2s infinite;
    }
    .title {
        img {
            height: 133px;
            margin: 0 0 52px;
        }
    }
    .title-double {
        font-size: 122px;
        color: $pyrat-blue;
    }
    .screen-intro-content {
        width: 100vw;
        height: 100vh;
        opacity: 0;
        display: flex;
        pointer-events: none;
        transition: opacity 0.5s ease-in-out;
        &.show {
            opacity: 1;
            pointer-events: all;
        }
    }
    .part {
        width: 50%;
        &.left {
            padding: 11.4vw 3.9vw 4.8vw 5.5vw;
            background-color: $pyrat-black;
        }
        &.right {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $pyrat-purple;
            svg {
                width: 60%;
            }
        }
    }
    p {
        @include font-PlexMono();
        font-size: 17px;
        line-height: 22px;
        color: $pyrat-white;
        letter-spacing: 0.05em;
        margin: 0 0 66px;
    }
    .tiles-container {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        margin: 0 0 20px;
    }
    .tile-scenario-btn {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: stretch;
        aspect-ratio: 1 / 1;
        border-radius: 12px;
        padding: 20px;
        border: 1px solid $pyrat-grey-3;
        background-color: $pyrat-white;
        .lottie-container,
        .img-container {
            flex: 1;
            border-radius: 6px;
            overflow: hidden;
            pointer-events: none;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &.absolute {
                position: relative;
                div {
                    position: absolute;
                    inset: 0;
                }
            }
        }
        .text-container {
            margin: auto 0 0;
            pointer-events: none;
        }
        .tile-title {
            @include font-PlexMono();
            font-size: 12px;
            letter-spacing: 0.05rem;
            color: $pyrat-grey-5;
            margin: 0 0 4px;
        }
        p {
            @include font-PlexMono();
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.05rem;
            color: $pyrat-grey-4;
            margin: 0;
        }
        &.tile-scenario-1 {
            .lottie-container {
                background: url('../img/grid.svg') center / 100% 100% no-repeat;
            }
        }
    }
    &.loading {
        .loading-text {
            display: flex;
        }
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
