.screen {
    position: absolute;
    inset: 0;
    opacity: 0;
    display: none;
    pointer-events: none;
    transition: opacity .5s ease;
    &.show {
        opacity: 1;
        display: block;
        pointer-events: all;
    }
}

.curtain {
    position: absolute;
    inset: 0;
    z-index: 3;
    opacity: 0;
    pointer-events: none;
    background-color: $pyrat-white;
    transition: opacity 1s ease;
    &.show {
        opacity: 1;
    }
}

.input-container {
    flex: 1;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    .input-range-color {
        --gradient: linear-gradient(90deg, var(--theme), var(--theme) 100%);
        --percent: 0;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 3px;
        &:before {
            content: '';
            background-image: var(--gradient);
            border-radius: 3px;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: var(--percent);
            height: 3px;
            z-index: -1;
        }
    }
    input {
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background: rgba($pyrat-white, 0.4) var(--gradient) no-repeat;
        background-size: var(--percent) 100%;
        -webkit-appearance: none;
        appearance: none;
        -webkit-tap-highlight-color: transparent;
        &::-webkit-slider-thumb {
            background-color: $pyrat-white;
            border: 1px solid $pyrat-black;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            -webkit-appearance: none;
            appearance: none;
        }
        &:focus::-webkit-slider-thumb,
        &::-webkit-slider-thumb:hover {
            background-color: $pyrat-white;
        }
        &::-moz-range-thumb {
            background-color: $pyrat-white;
            border: 1px solid $pyrat-black;
            border-radius: 50%;
            width: 25px;
            height: 25px;
        }
        &:focus::-moz-range-thumb,
        &::-moz-range-thumb:hover {
            background-color: $pyrat-white;
        }
        &:focus {
            outline: none;
        }
    }
}
