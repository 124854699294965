.menubar {
    height: 36px;
    display: none;
    align-items: center;
    gap: 0 10px;
    padding: 0 10px;
    background-color: $pyrat-grey;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9000;
    [data-screenactive="scenario-1"] &,
    [data-screenactive="scenario-2"] & {
        display: flex;
    }
    .menu-container {
        position: relative;
        .text {
            @include font-Formula();
            font-weight: 500;
            font-size: 16px;
            letter-spacing: .05rem;
            color: $pyrat-grey-5;
            pointer-events: none;
            white-space: nowrap;
            transition: color .2s ease;
        }
        .menu-trigger {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            padding: 5px 10px 2px;
            background-color: transparent;
            transition: background-color .2s ease;
        }
        .menu {
            flex-direction: column;
            gap: 3px 0;
            border: 1px solid $pyrat-grey-2;
            border-radius: 6px;
            padding: 3px;
            background-color: $pyrat-grey-0;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
            position: absolute;
            top: calc(100% + 3px);
            left: 0;
            display: none;
            hr {
                margin: 0;
                border: 0;
                background-color: $pyrat-grey-2;
            }
            .menu-item-trigger {
                min-width: 252px;
                border-radius: 6px;
                padding: 11px 10px 9px;
                text-align: start;
                background-color: transparent;
                transition: background-color .2s ease;
                &:hover {
                    background-color: $pyrat-blue;
                    .text {
                        color: $pyrat-white;
                    }
                }
                .text {
                    pointer-events: none;
                }
            }
        }
        &.show {
            .menu-trigger {
                background-color: $pyrat-blue;
                .text {
                    color: $pyrat-white;
                }
            }
            .menu {
                display: flex;
            }
        }
    }
    .today,
    .time {
        @include font-Formula();
        font-weight: 500;
        font-size: 16px;
        letter-spacing: .05rem;
        color: $pyrat-grey-5;
        margin: 6px 0 0;
    }
    .today {
        margin-left: auto;
        margin-right: 30px;
    }
    .time {
        font-feature-settings: 'tnum';
        font-variant-numeric: tabular-nums;
    }
}
