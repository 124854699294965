@mixin declare-font-face($font-family, $font-filename, $font-weight: normal, $font-style: normal, $font-stretch: normal) {
    @font-face {
        font-family: '#{$font-family}';
        src: url(#{$font-filename}.woff2) format('woff2');
        font-weight: $font-weight;
        font-style: $font-style;
        font-stretch: $font-stretch;
        font-display: swap;
    }
}

@mixin size($width: auto, $height: auto) {
    height: $height;
    width: $width;
}

@mixin font-Formula() {
    font-family: PPFormula, sans-serif;
}

@mixin font-PlexMono() {
    font-family: IBMPlexMono, sans-serif;
    font-weight: 400;
}

@mixin font-PlexSans() {
    font-family: IBMPlexSans, sans-serif;
    font-weight: 300;
}

@mixin absolute-center($axis: 'both') {
    position: absolute;
    @if $axis == 'y' {
        top: 50%;
        transform: translateY(-50%);
    }
    @if $axis == 'x' {
        left: 50%;
        transform: translateX(-50%);
    }
    @if $axis == 'both' {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin placeholder($color: $grayLight) {
    &::placeholder {
        color: $color;
    }
}

@mixin line-clamp($nbLine: 1) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $nbLine;
    overflow: hidden;
}

@mixin font-size($fluid-type-font-min, $fluid-type-font-max) {
	$slope: ($fluid-type-font-max - $fluid-type-font-min) / ($fluid-type-screen-max - $fluid-type-screen-min);
	$yAxisIntersection: -$fluid-type-screen-min * $slope + $fluid-type-font-min;

    font-size: clamp(#{$fluid-type-font-min}, #{$yAxisIntersection} + #{$slope} * 100vw, #{$fluid-type-font-max});
}
