.window {
    @include absolute-center();
    width: 640px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid $pyrat-grey-3;
    background-color: $pyrat-white;
    box-shadow: 15px 15px 20px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    @include font-Formula();
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    .top {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $pyrat-grey-0;
        cursor: move;
    }
    .window-content {
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-inline-start: 10px 20px;
    }
    .bottom {
        height: 72px;
        border-top: 1px solid $pyrat-grey-3;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;
        gap: 0 15px;
        padding: 15px 35px;
    }
}

.file {
    width: 160px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
        width: 100px;
        height: 100px;
        margin: 0 0 3px;
        border-radius: 6px;
        padding: 6px 15px;
        svg {
            width: 100%;
        }
    }
    .name {
        width: 140px;
        border-radius: 6px;
        padding: 7px 25px 8px;
        color: $pyrat-black;
        text-align: center;
        overflow-wrap: break-word;
    }
    &.neutral {
        width: 100%;
        margin: 0 0 10px;
        .name {
            width: 100%;
            color: $pyrat-white;
        }
    }
}
