.popin {
    width: 100vw;
    height: 100vh;
    background-color: rgba($pyrat-black, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9002;
    display: none;
    &.open {
        display: flex;
        animation: popin-open 0.3s ease both;
    }
    &.close {
        animation: popin-close 0.3s ease both;
    }
    &.popin-infos {
        .popin-content {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            .slides {
                max-width: 1920px;
                height: 100%;
                margin: 0 auto;
                overflow: scroll;
                padding: 50px 70px 50px 80px;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            .slide {
                display: flex;
                gap: 0 30px;
                align-items: flex-start;
                padding: 0 0 45px;
                scroll-margin-top : 50px;
            }
            .left,
            .right {
                width: 50%;
                border-radius: 12px;
                border: 1px solid $pyrat-grey-3;
                box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.15);
            }
            .left {
                display: flex;
                flex-direction: column;
                overflow: hidden;
            }
            .right {
                height: 100%;
                display: flex;
                gap: 0 5%;
                padding: 76px 98px 100px 60px;
                background-color: $pyrat-grey;
                position: relative;
            }
            .img-container {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            .legend {
                background-color: $pyrat-grey-0;
                p {
                    @include font-Formula();
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    color: $pyrat-grey-5;
                    margin: 0;
                    padding: 11px 15px;
                }
            }
            .side {
                flex: 0 0 28%;
                .folio {
                    @include font-Formula();
                    font-weight: 300;
                    font-size: 134px;
                    line-height: 1;
                    letter-spacing: 0.05rem;
                    color: $pyrat-black;
                    margin: -19px 0 -10px 0;
                }
                .subtitle {
                    @include font-Formula();
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.05rem;
                    color: $pyrat-black;
                }
            }
            .main {
                display: flex;
                flex-direction: column;
                .title {
                    @include font-Formula();
                    font-weight: 800;
                    font-size: 28px;
                    line-height: 38px;
                    letter-spacing: 0.05rem;
                    color: $pyrat-black;
                    margin: 0 0 26px;
                }
                p {
                    @include font-Formula();
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 34px;
                    color: $pyrat-black;
                    margin: 0 0 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &.popin-help {
        align-items: center;
        justify-content: center;
        padding: 6.25rem 4.375rem 3.75rem 5rem;
        .popin-content {
            width: 100vw;
            height: 100vh;
            max-width: 1770px;
            max-height: 920px;
            position: relative;
        }
        .btn.help {
            position: absolute;
            top: 20px;
            right: 20px;
        }
        .bloc-help {
            border-radius: 18px;
            position: absolute;
            &.infos {
                width: 72px;
                height: 54px;
                top: 0.5%;
                left: 24.6%;
            }
            &.network {
                width: 72px;
                height: 54px;
                top: 34.4%;
                left: 48%;
            }
            &.start {
                width: 72px;
                height: 54px;
                top: 68%;
                left: 93.3%;
            }
            &.analyse {
                width: 72px;
                height: 54px;
                top: 94%;
                left: 7.8%;
            }
            &.precision {
                width: 72px;
                height: 54px;
                top: 94%;
                left: 36.3%;
            }
        }
        .tooltip {
            width: 300px;
            @include font-Formula();
            font-weight: 500;
            font-size: 16px;
            line-height: 1;
            border-radius: 6px;
            padding: 15px 15px 11px;
            text-align: center;
            @include absolute-center(x);
            background-color: $pyrat-yellow;
            &::before {
                content: '';
                width: 22px;
                height: 30px;
                position: absolute;
                left: 50%;
                @include absolute-center(x);
                background: url('../img/icon-arrow-help.svg') center / auto 100% no-repeat;
            }
            &.top {
                bottom: calc(100% + 54px);
                &::before {
                    top: calc(100% + 12px);
                    transform: translateX(-50%) rotate(180deg);
                    animation: bounceBottom 0.5s infinite alternate ease-in-out;
                }
            }
            &.bottom {
                top: calc(100% + 54px);
                &::before {
                    bottom: calc(100% + 12px);
                    transform: translateX(-50%) rotate(0deg);
                    animation: bounceTop 0.5s infinite alternate ease-in-out;
                }
            }
        }
    }
}

@keyframes popin-open {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes popin-close {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes bounceBottom {
    from {
        top: calc(100% + 5px);
    }
    to {
        top: calc(100% + 19px);
    }
}

@keyframes bounceTop {
    from {
        bottom: calc(100% + 5px);
    }
    to {
        bottom: calc(100% + 19px);
    }
}
