@include declare-font-face('PPFormula', '../fonts/PPFormula-Light', 300);
@include declare-font-face('PPFormula', '../fonts/PPFormula-Medium', 500);
@include declare-font-face('PPFormula', '../fonts/PPFormula-Extrabold', 800);
@include declare-font-face('PPFormula', '../fonts/PPFormula-ExtendedBold', 700);

@include declare-font-face('IBMPlexSans', '../fonts/IBMPlexSans-Regular-Latin1', 400);
@include declare-font-face('IBMPlexSans', '../fonts/IBMPlexSans-Regular-Latin2', 400);
@include declare-font-face('IBMPlexSans', '../fonts/IBMPlexSans-Regular-Latin3', 400);

@include declare-font-face('IBMPlexMono', '../fonts/IBMPlexMono-Medium-Latin1', 300);
@include declare-font-face('IBMPlexMono', '../fonts/IBMPlexMono-Medium-Latin2', 300);
@include declare-font-face('IBMPlexMono', '../fonts/IBMPlexMono-Medium-Latin3', 300);
